<template>
  <div class="absolute inset-0 flex items-center justify-center">
    <Container class="relative z-20 flex items-center h-full text-white pointer-events-none">
      <div class="w-full">
        <AnimatedText :copy="title"
                      :visible="textVisible"
                      tag="h2"
                      class="max-w-4xl px-6 text-4xl leading-tight sm:justify-center lg:px-24 md:text-5xl md:leading-tight lg:text-6xl lg:leading-tight"
        />
      </div>
    </Container>
    <div class="absolute duration-75 rounded-full blur-2xl w-[120vw] h-[120vw]"
         :class="[`bg-${color}`]"
         :style="{
           transform: `scale3d(${scale}, ${scale}, 1)`,
         }"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import AnimatedText from './animated-text.vue'

interface gradientCircle {
  color: string
  trigger: number
  title: string
}

const props = defineProps<gradientCircle>()

const textVisible = ref(false)
const scale = ref(0.2)

watch(
  () => props.trigger,
  trigger => {
    if (trigger > 1 || trigger < 0) textVisible.value = false
    else textVisible.value = true
    scale.value = Math.min(1, Math.max(0.2, trigger + 0.15))
  }
)
</script>
